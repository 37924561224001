import { FC } from 'react';
import { ISvgIcon } from 'types';

const MenuAccountSvg: FC<ISvgIcon> = ({
  width = '24',
  height = '25',
  fill = 'white',
  className = '',
  fillOpacity = '0.6',
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M15.1034 19.9605H21.31C21.5957 19.9605 21.8272 20.1921 21.8272 20.4778V21.5122C21.8272 21.7978 21.5957 22.0294 21.31 22.0294H15.1034C14.8177 22.0294 14.5861 21.7978 14.5861 21.5122V20.4778C14.5861 20.1921 14.8177 19.9605 15.1034 19.9605Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
        <path
          d="M15.1034 16.8572H21.31C21.5957 16.8572 21.8272 17.0888 21.8272 17.3744V18.4089C21.8272 18.6945 21.5957 18.9261 21.31 18.9261H15.1034C14.8177 18.9261 14.5861 18.6945 14.5861 18.4089V17.3744C14.5861 17.0887 14.8177 16.8572 15.1034 16.8572Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
        <path
          d="M15.1034 13.7539H21.31C21.5957 13.7539 21.8273 13.9855 21.8273 14.2711V15.3056C21.8273 15.5912 21.5957 15.8228 21.31 15.8228H15.1034C14.8177 15.8228 14.5861 15.5912 14.5861 15.3056V14.2711C14.5861 13.9854 14.8177 13.7539 15.1034 13.7539Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5395 6.66277C17.6297 6.7646 17.6825 6.89411 17.6895 7.02998L17.6895 12.2022C17.6895 12.4878 17.4579 12.7194 17.1723 12.7194H15.1034C14.2464 12.7194 13.5517 13.4141 13.5517 14.271V21.5122C13.5517 21.7978 13.3201 22.0294 13.0345 22.0294H2.69009C2.40444 22.0294 2.17285 21.7978 2.17285 21.5122V2.89224C2.17285 2.60659 2.40444 2.375 2.69009 2.375H13.0345C13.1703 2.38193 13.2999 2.43479 13.4017 2.52498L17.5395 6.66277ZM12.9552 6.64618C12.9552 6.92232 13.1791 7.14618 13.4552 7.14618H16.5758L12.9552 3.52562V6.64618ZM9.57604 8.12296C9.7861 8.12296 9.99616 8.33302 9.99616 8.54308V9.03321H11.2565C11.4665 9.03321 11.6766 9.24326 11.6766 9.45332V9.59336C11.6766 9.80341 11.4665 10.0135 11.2565 10.0135H8.52576C8.31571 10.0135 8.10565 10.1535 7.96561 10.2935C7.75556 10.5036 7.68554 10.7137 7.68554 10.9237C7.68554 11.1338 7.82557 11.3438 7.96561 11.4839C8.10565 11.6239 8.31571 11.7639 8.52576 11.7639H10.4163C10.9064 11.7639 11.4849 11.9338 11.8431 12.2909C12.1591 12.6059 12.3068 13.0943 12.3068 13.5844C12.3068 14.0746 12.1667 14.4947 11.8867 14.8448C11.6066 15.1949 11.1165 15.4049 10.6263 15.4049H10.1362V16.0351C10.1362 16.2451 9.92614 16.4552 9.71608 16.4552H9.50602C9.29597 16.4552 9.08591 16.2451 9.08591 16.0351V15.4049H7.5455C7.33544 15.4049 7.12539 15.1949 7.12539 14.9848V14.7747C7.12539 14.5647 7.26543 14.4246 7.47548 14.4246H10.4163C10.6963 14.4246 10.9064 14.3546 11.0464 14.2846C11.2565 14.0746 11.3265 13.8645 11.3265 13.6544C11.3265 13.3744 11.1865 13.1643 11.0464 13.0243C10.8364 12.8142 10.6263 12.7442 10.4163 12.7442H8.52576C8.10565 12.7442 7.61552 12.5341 7.26543 12.184C6.91533 11.904 6.70528 11.4138 6.70528 10.9237C6.70528 10.4336 6.91533 9.94345 7.26543 9.66337C7.5455 9.31328 8.03563 9.10322 8.52576 9.10322H9.01589V8.54308C9.01589 8.33302 9.15593 8.12296 9.36599 8.12296H9.57604Z"
          fill={fill}
          fillOpacity={fillOpacity}
        />
      </svg>
    </>
  );
};

export default MenuAccountSvg;
