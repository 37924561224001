const toggleDarkMode = () => {
  if (
    localStorage.theme === 'dark' ||
    !('theme' in localStorage)
    // (!('theme' in localStorage) &&
    //   window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

export default toggleDarkMode;
